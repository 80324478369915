@import "variables";
@import "./mixins/mixins";
@import "./components/colors";

.weekview-container,
.dayview-container {
  --height: 500px !important;
  height: 500px !important;
}
.cal-month-view .cal-header .cal-cell {
  text-transform: uppercase !important;
  color: #97a1a6;
  font-size: 12.8px;
  letter-spacing: 0;
  line-height: 16px;
}

.cal-today {
  color: #1ea5c7;
  font-family: Roboto;
  font-size: 12.8px;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: capitalize;
}
.cal-today span {
  color: #1ea5c7;
  font-family: Roboto;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: capitalize;
}
.cal-header {
  border: none;
  text-align: left;
}
.cal-time {
  font-weight: normal;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #d5dbde;
}
.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #d5dbde;
}
.cal-week-view .cal-time {
  font-weight: normal;
  color: #97a1a6;
  font-size: 11px;
  padding-top: 5px;
  letter-spacing: -0.3px;
  line-height: 13px;
  text-align: center;
}
.cal-event-title {
  color: #fff;
}
.cal-week-view .cal-event {
  background: none;
  border: none;
}
.cal-week-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
}
.cal-month-view .cal-day-badge {
  border-radius: none;
  background-color: transparent !important;
  color: #97a1a6;
  font-family: Roboto;
  font-size: 11px;
  letter-spacing: -0.3px;
  line-height: 12px;
  text-align: center;
}
.vaction {
  background-color: #ffe9a1;
}
.cal-month-view .cal-cell-top {
  min-height: 0px !important;
  transform: translateY(0px);
}
.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
  margin-top: 7px;
  margin-right: 0px;
  float: none;
}
.cal-month-view .cal-day-cell {
  min-height: 107px;
  width: 52px;
  text-align: center;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.2em;
}

span.cal-day-number{
 font-size: rem(19.2) !important;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: $black;
}
.day-info {
  color: #198ca9 !important;
}

mwl-calendar-day-view {
  .cal-time {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background: $white;
  }
}

mwl-calendar-week-view {
  .cal-time-label-column {
    .cal-hour {
      .cal-hour-segment {
        background: $white !important;
      }
    }
  }
}

.cal-current-time-marker {
  height: 1px !important;
  background-color: $black !important;
  &:before {
    background-color: $black !important;
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    left: -6.5px;
    top: -5.5px;
  }
}

.comparison-view {
  .cal-day-columns {
    flex: 0;
    width: calc(100% - 70px);
    .cal-day-column {
      mwl-calendar-week-view-current-time-marker:not(.first-column) {
        .cal-current-time-marker {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
// Theming

.cabinet-confirmed-v1 {
  border-left: 4px solid #2ea81b;
  background-color: #eaf6e8 !important;
  border-radius: 4px 0 0 4px;
  color: #2ea81b;
}
.cabinet-confirmed-v1-weekmonth {
  background-color: #2ea81b !important;
  --color: white;
  color: white;
}
.cabinet-confirmed-v2 {
  border-left: 4px solid #1ea5c7;
  background-color: #e3f9ff;
  border-radius: 4px 0 0 4px;
  color: #1ea5c7;
}
.cabinet-confirmed-v2-weekmonth {
  background: #1ea5c7 !important;
  color: #fff;
}
.online-confirmed-v1 {
  width: 100%;
  border-left: 4px solid #3093f8 !important;
  background-color: #eaf4fe !important;
  color: #3093f8;
  border-radius: 4px 0 0 4px;
  z-index: 100;
}
.online-confirmed-v1-weekmonth {
  background: #3093f8 !important ;
  color: #000;
}
.online-confirmed-v2 {
  border-left: 4px solid #9b57e0;
  background-color: #f7edff !important;
  color: #9b57e0;
  border-radius: 4px 0 0 4px;
}
.online-confirmed-v2-weekmonth {
  background: #9b57e0 !important;
  color: #000;
}

.warning-v1 {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid #f77c00;
  background-color: #feecd9;
  color: #f77c00;
}
.warning-v1-weekMonth {
  background: #f77c00 !important;
  color: #fff;
}
.warning-v2 {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid #f23d3d;
  background-color: #fef1e5 !important;
  color: #f23d3d;
}
.warning-v2-weekMonth {
  background: #f23d3d !important;
  color: #fff;
}
.note-v1 {
  background-color: #ffe9a1 !important;
  color: #000;
}
.note-v2 {
  background-color: #e3e9ec !important;
  color: #000;
}
.cancelled-v1 {
  background: #97a1a6 !important;
  color: #000;
}
.cancelled-v2 {
  border: 2px solid #97a1a6 !important;
  border-left: 4px solid #97a1a6;
  border-radius: 4px 0 0 4px;
  background-color: #fff;
}
.cabinet-not-confirmed-v1 {
  border-left: 4px solid #d5eed1 !important;
  border-radius: 4px 0 0 4px;
  background-color: none !important;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #eaf6e8 10px,
    #eaf6e8 calc(2 * 10px)
  ) !important;
  color: #2ea81b;
}
.cabinet-not-confirmed-v1-weekmonth {
  background: #d5eed1 !important;
  color: #000;
}
.cabinet-not-confirmed-v2 {
  border-left: 4px solid #c7f3ff !important;
  border-radius: 4px 0 0 4px;
  background-color: none !important;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #e3f9ff 10px,
    #e3f9ff calc(2 * 10px)
  ) !important;
}
.cabinet-not-confirmed-v2-weekmonth {
  background: #c7f3ff !important;
  color: #000;
}
.online-not-confirmed-v2 {
  border-left: 4px solid #eddbff !important;
  border-radius: 4px 0 0 4px;
  background-color: none !important;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #f7edff 10px,
    #f7edff calc(2 * 10px)
  ) !important;
  color: #97a1a6;
}
.online-not-confirmed-v1 {
  border-left: 4px solid #d6e9fe;
  border-radius: 4px 0 0 4px;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #eaf4fe 10px,
    #eaf4fe calc(2 * 10px)
  ) !important;
  color: #97a1a6;
}
.online-not-confirmed-v1-weekMonth {
  background: #d6e9fe !important;
  color: #000;
}

.online-not-confirmed-v2-weekMonth {
  background: #c7f3ff !important;
  color: #000;
}
.holidays {
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #ffe9a1 10px,
    #ffe9a1 calc(2 * 10px)
  ) !important;
}
.border-none {
  border: none !important;
}
.vacation-bg {
  background-color: #ffe9a1 !important;
}
.cal-month-view .cal-day-cell.cal-today {
  background-color: none !important;
}
.caps {
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #97a1a6 !important;
  font-size: 12.8px;
  letter-spacing: 0;
  line-height: 16px;
}
.day-nr,
.cal-today {
  background-color: none !important;
  color: #97a1a6 !important;
  font-family: Roboto;
  font-weight: 500;
  font-size: rem(12.8);
  letter-spacing: -1.5px;
}

span.current {
  color: $blue-5 !important;
}
.cal-today {
  color: #1ea5c7;
}
.cal-week-view .cal-header.cal-today {
  background-color: #fff;
}

.cal-week-view {
  border-top: none;
}
.cal-today:last-child {
  color: #1ea5c7;
  font-weight: 500;
}
.cal-day-headers .cal-header {
  border: none !important;
}
.cal-week-view .cal-day-headers span {
  font-weight: normal;
  opacity: 1;
}
.cal-events-row {
  display: none !important;
}
.cal-day-headers {
  height: 60px;
}
.cal-week-view .cal-hour-odd {
  background-color: #fff !important;
}

.monthview-selected {
  border-radius: 8px;
  background-color: #1ea5c7 !important;
  color: white;
}
.list-appointment {
  border-left: rem(4) solid;
  border-radius: 4px 0 0 4px;
}

.calendar-theme-1 {
  color: var(--ion-neutral-black-color) !important;
  @include calendar-color-theme($theme-1);
}
.calendar-theme-2 {
  color: var(--ion-neutral-black-color) !important;
  @include calendar-color-theme($theme-2);
}

.schedule-private {
  background-color: none !important;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #f7edff 10px,
    #f7edff calc(2 * 10px)
  ) !important;
  color: #97a1a6;
}
.schedule-normal {
  background-color: none !important;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #eaf6e8 10px,
    #eaf6e8 calc(2 * 10px)
  ) !important;
  color: #2ea81b;
}

.schedule-booked {
  background-color: none !important;
  background: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 10px,
    #FFE9A1 10px,
    #FFE9A1 calc(2 * 10px)
  ) !important;
  color: #FFE9A1;
}


app-comparativ {
  swiper {
    &.cal-day-headers,
    &.cal-content {
      &.is-locked {
        .swiper-slide {
          flex: 1;
        }
      }
    }
  }

  .scroll-nav-button-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;
    cursor: pointer;

    .left {
      position: absolute;
      left: 24px;
      bottom: 24px;
    }
    .right {
      position: absolute;
      right: 24px;
      bottom: 24px;
    }
  }
  .bg-white {
    background: #fff;
  }
}

.cal-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: rem(11);
  word-wrap: break-word;
  opacity: 0.9;

  &.cal-tooltip-top {
    padding: 8px 0;
    margin-top: rem(-8);
    .cal-tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -7px;
      border-width: 8px 7px 0;
      border-top-color: $blue-5;
    }
  }

  &.cal-tooltip-right {
    padding: 0 8px;
    margin-left: rem(8);
    .cal-tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -7px;
      border-width: 7px 8px 7px 0;
      border-right-color: $blue-5;
    }
  }
  &.cal-tooltip-bottom {
    padding: 8px 0;
    margin-top: rem(8);
    .cal-tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -7px;
      border-width: 0 7px 8px;
      border-bottom-color: $blue-5;
    }
  }

  &.cal-tooltip-left {
    padding: 0 8px;
    margin-left: rem(-8);
    .cal-tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -7px;
      border-width: 7px 0 7px 8px;
      border-left-color: $blue-5;
    }
  }

  .cal-tooltip-inner {
    font-size: rem(12.8) !important;
    line-height: rem(16) !important;
    font-weight: 500;
    padding: 9px 8px 7px 8px;
    text-align: center;
    border-radius: rem(8);
    color: #fff;
    background-color: $blue-5;
  }

  .cal-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}

@import "../variables.scss";
@import "../mixins/mixins";

.ion-color-biz-primary {
  --ion-color-base: var(--ion-primary-color-step-100);
  --ion-color-base-rgb: var(--ion-primary-color-step-100-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: rgb(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-primary-color-step-70);
  --ion-color-tint: var(--ion-primary-color-step-30);
  --background-hover-opacity: 0.2 !important;
  --box-shadow: none !important;
}

.ion-color-biz-disabled-primary {
  --ion-color-base: var(--ion-neutral-grey-light-b-color) !important;
  --ion-color-base-rgb: var(--ion-neutral-grey-light-b-color-rgb) !important;
  --ion-color-contrast: var(--ion-neutral-grey-default-color);
  --ion-color-contrast-rgb: rgb(
    --ion-neutral-grey-default-color-rgb
  ) !important;
  --ion-color-shade: var(--ion-neutral-grey-medium-color) !important;
  --ion-color-tint: var(--ion-neutral-grey-medium-color) !important;
  --background-hover-opacity: 0.2 !important;
  --box-shadow: none !important;
}

.ion-color-biz-disabled-secondary {
  --ion-color-base: var(--ion-neutral-grey-default-color) !important;
  --ion-color-base-rgb: var(--ion-neutral-grey-default-color-rgb) !important;
  --ion-color-contrast: var(--ion-neutral-grey-light-b-color);
  --ion-color-contrast-rgb: rgb(
    --ion-neutral-grey-light-b-color-rgb
  ) !important;
  --ion-color-shade: var(--ion-neutral-grey-medium-color) !important;
  --ion-color-tint: var(--ion-neutral-grey-medium-color) !important;
  --background-hover-opacity: 0.08 !important;
  --box-shadow: none !important;
}

.neutral-grey-medium-color {
  color: var(--ion-neutral-grey-medium-color) !important;
}

.error {
  color: var(--ion-primary-error-color);
}

.white {
  color: #fff !important;
  --color: #fff !important;
}

.black-color {
  color: #000 !important;
}

.biz-neutral-gray-default-color {
  color: var(--ion-neutral-grey-default-color) !important;
}

.primary-color-step-100 {
  --color: var(--ion-primary-color-step-100) !important;
  color: var(--color) !important;
}

.isFocused {
  background: var(--ion-primary-color-step-5) !important;
}

.defaultGrayColor {
  color: var(--ion-neutral-grey-default-color) !important;
}

.primary-error-color {
  --color: var(--ion-primary-error-color) !important;
  color: var(--color) !important;
}

.primary-error-bg-color {
  --background: var(--ion-primary-error-color) !important;
  background: var(--background) !important;
}
.primary-bg-color-step-30 {
  --background: var(--ion-primary-color-step-30) !important;
  background: var(--background) !important;
}

.status-green-color-step-100 {
  --color: var(--ion-status-green-color-step-100) !important;
  color: var(--color) !important;
}

.primary-color-step-110 {
  --color: var(--ion-primary-color-step-110) !important;
  color: var(--color) !important;
}

.biz-neutral-gray-default-color-bg {
  background: var(--ion-neutral-grey-default-color) !important;
}

.red-bg {
  background: red !important;
}

.pink-bg {
  background: pink !important;
}

.white-bg {
  background: white;
  --background: white;
}
.orange-bg-color-step-10 {
  background: var(--ion-status-orange-color-step-10) !important;
}

.orange-bg-color-step-20 {
  background: var(--ion-status-orange-color-step-20) !important;
}
.orange-bg-color-step-15 {
  background: var(--ion-status-orange-color-step-15) !important;
}

.green-bg-color-step-20 {
  background: var(--ion-status-green-color-step-20) !important;
}

.priamry-color-text {
  --color: var(--ion-primary-color-text) !important;
  color: var(--color) !important;
}

.primary-color-step-5 {
  --background: var(--ion-primary-color-step-5);
  background: var(--background) !important;
}

@media (min-width: $desktop-width) {
  .web-grey-light-b-bg-color {
    --background: var(--ion-neutral-grey-light-b-color) !important;
    background: var(--background) !important;
  }

  .web-white-page {
    --background: var(--app-white-color, #ffffff);
    --ion-background-color: var(--background, #ffffff);
    --ion-background-color-rgb: 255, 255, 255;
    background: $white;
  }
}

.bg-ion-neutral-grey-light-b-color {
  background: var(--ion-neutral-grey-light-b-color) !important;
}

.primary-bg-color-step-100 {
  background: var(--ion-primary-color-step-100) !important;
}

.orange-bg-color-step-10 {
  background: var(--ion-status-orange-color-step-10) !important;
}
.schedule-private-bg-color {
  background-color: #f7edff !important;
}
.isBreakTime-bg-color {
  background-color: #fff !important;
}

.bg-green-2 {
  background-color: $green-2;
  --background-color: $green-2;
}

.bg-green-4 {
  background-color: $green-4;
  --background-color: $green-4;
}

.bg-blue-2 {
  background-color: $blue-2;
  --background-color: $blue-2;
}

.bg-blue-4 {
  background-color: $blue-4;
  --background-color: $blue-4;
}

.bg-blue-5 {
  background-color: $blue-5;
  --background-color: $blue-5;
}
.bg-blue-6 {
  background-color: $blue-6;
  --background-color: $blue-6;
}

.bg-warning-2 {
  background-color: $warning-2;
  --background-color: $warning-2;
}

.bg-warning-3 {
  background-color: $warning-3;
  --background-color: $warning-3;
}

.bg-yellow-3 {
  background-color: $yellow-3;
  --background-color: $yellow-3;
}

.bg-purple {
  background-color: $purple !important;
  --background-color: $purple !important;
}

.bg-purple-2 {
  background-color: $purple-2 !important;
  --background-color: $purple-2 !important;
}

.bg-red-2 {
  background-color: $red-2 !important;
  --background-color: $red-2 !important;
}

.bg-red-3 {
  background-color: $red-3 !important;
  --background-color: $red-3 !important;
}

.bg-grey-2 {
  background-color: $grey-2 !important;
  --background-color: $grey-2 !important;
}

.color-white {
  color: $white;
  --color: $white;
}

.color-black {
  color: $black !important;
  --color: $black !important;
}

.color-green-2 {
  color: $green-2 !important;
  --color: $green-2 !important;
}

.color-blue-2 {
  color: $blue-2 !important;
  --color: $blue-2 !important;
}

.color-blue-5 {
  color: $blue-5 !important;
  --color: $blue-5 !important;
}

.color-warning-2 {
  color: $warning-2 !important;
  --color: $warning-2 !important;
}

.color-red-2 {
  color: $red-2 !important;
  --color: $red-2 !important;
}

.color-light-grey-3 {
  color: $light-grey-3 !important;
  --color: $light-grey-3 !important;
}

.color-light-grey-5 {
  color: #97A1A6 !important;
  --color: #97A1A6 !important;
}

.color-purple {
  color: $purple !important;
  --color: $purple !important;
}

.bg-green-3 {
  background-color: $green-3 !important;
  --background-color: $green-3 !important;
}

.bg-purple {
  color: $purple !important;
  --color: $purple !important;
}
.bg-light-grey-4{
  background-color: $light-grey-4 !important;
  --background-color: $light-grey-4 !important;
  --ion-item-background: $light-grey-4 !important
}

$theme-1: (
  "1CN": (
    "main": $green-3,
    "secondary": $green-4,
    "tertiary": $light-grey-3,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "1CY": (
    "main": $green-2,
    "secondary": $green-4,
    "tertiary": $green-2,
    "text": inherit,
    "icon": $green-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "1N": (
    "main": $yellow-3,
    "secondary": $yellow-3,
    "tertiary": inherit,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "1NUL": (
    "main": $light-grey-3,
    "secondary": $light-grey-3,
    "tertiary": $green-2,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "1OY": (
    "main": $blue-2,
    "secondary": $blue-4,
    "tertiary": $green-2,
    "text": var(--ion-neutral-black-color),
    "icon": $blue-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "1ON": (
    "main": $blue-3,
    "secondary": $blue-4,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "1WM": (
    "main": $warning-2,
    "secondary": $warning-3,
    "text": var(--ion-neutral-black-color),
    "icon": $red-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2CN": (
    "main": $green-3,
    "secondary": $green-4,
    "tertiary": $light-grey-3,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "2CY": (
    "main": $green-2,
    "secondary": $green-4,
    "tertiary": $green-2,
    "text": inherit,
    "icon": $green-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2N": (
    "main": $yellow-3,
    "secondary": $yellow-3,
    "tertiary": inherit,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "2NUL": (
    "main": $light-grey-3,
    "secondary": $light-grey-3,
    "tertiary": $green-2,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "2OY": (
    "main": $blue-2,
    "secondary": $blue-4,
    "tertiary": $green-2,
    "text": var(--ion-neutral-black-color),
    "icon": $blue-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2ON": (
    "main": $blue-3,
    "secondary": $blue-4,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "2WM": (
    "main": $warning-2,
    "secondary": $warning-3,
    "text": var(--ion-neutral-black-color),
    "icon": $red-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2CS": (
    "main": $red-3,
    "secondary": $red-3,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "alt-text": $light-grey-3,
    "alt-icon": $light-grey-3,
  ),
);
$theme-2: (
  "1CN": (
    "main": $blue-7,
    "secondary": $blue-6,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "1CY": (
    "main": $blue-5,
    "secondary": $blue-6,
    "text": inherit,
    "icon": $blue-5,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "1N": (
    "main": $grey-2,
    "secondary": $grey-2,
    "tertiary": inherit,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "1NUL": (
    "main": $light-grey-3,
    "secondary": $light-grey-3,
    "tertiary": $green-2,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "1OY": (
    "main": $purple,
    "secondary": $purple-2,
    "text": var(--ion-neutral-black-color),
    "icon": $purple,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "1ON": (
    "main": $purple-3,
    "secondary": $purple-2,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "1WM": (
    "main": $red-2,
    "secondary": $red-3,
    "text": var(--ion-neutral-black-color),
    "icon": $red-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2CN": (
    "main": $blue-7,
    "secondary": #c8d2d4,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "2CY": (
    "main": $blue-5,
    "secondary": $blue-6,
    "text": inherit,
    "icon": $blue-5,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2N": (
    "main": $grey-2,
    "secondary": $grey-2,
    "tertiary": inherit,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "2NUL": (
    "main": $light-grey-3,
    "secondary": $light-grey-3,
    "tertiary": $green-2,
    "text": var(--ion-neutral-black-color),
    "icon": inherit,
  ),
  "2OY": (
    "main": $purple,
    "secondary": $purple-2,
    "text": var(--ion-neutral-black-color),
    "icon": $purple,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2ON": (
    "main": $purple-3,
    "secondary": $purple-2,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "is-gradient": "true",
  ),
  "2WM": (
    "main": $red-2,
    "secondary": $red-3,
    "text": var(--ion-neutral-black-color),
    "icon": $red-2,
    "alt-text": $white,
    "alt-icon": $white,
  ),
  "2CS": (
    "main": $red-3,
    "secondary": $red-3,
    "text": var(--ion-neutral-black-color),
    "icon": $light-grey-3,
    "alt-text": $light-grey-3,
    "alt-icon": $light-grey-3,
  ),
);
.gradient-white-bg {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 50%
  ) !important;
}

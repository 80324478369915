/* roboto-300 - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-300italic - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-regular - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-500 - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-italic - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local('Roboto Italic'), local('Roboto-Italic'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-500italic - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-700 - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* roboto-700italic - latin-ext_latin */

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/assets/fonts/Roboto/roboto-v20-latin-ext_latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* source-sans-pro-300 - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-300italic - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300italic.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-300italic.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-regular - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-regular.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-600 - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-italic - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-italic.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-italic.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-600italic - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600italic.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-600italic.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-700 - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* source-sans-pro-700italic - latin-ext_latin */

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700italic.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'), url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/Source_Sans_Pro/source-sans-pro-v13-latin-ext_latin-700italic.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}


/* oassword font */

@font-face {
    font-family: "pass-font";
    src: url("/assets/font/pass-font.eot?zzz");
    src: url("/assets/font/pass-font.eot?zzz#iefix") format("embedded-opentype"), url("/assets/font/pass-font.woff2?zzz") format("woff2"), url("/assets/font/pass-font.woff?zzz") format("woff"), url("/assets/font/pass-font.ttf?zzz") format("truetype"), url("/assets/font/pass-font.svg?zzz#pass-font") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
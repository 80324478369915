@media (min-width: $desktop-width) {
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-flex {
    display: flex;
  }
}

@import '../variables';
@import '../mixins/mixins';

.biz {
  ion-item {
    label {
      display: block !important;
    }
    &.item-mh-40 {
      --min-height: 40px;
    }

    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;
    --ion-safe-area-left: 0;
    --ion-border-color: var(--ion-neutral-grey-medium-color);
    --highlight-color-focused: none !important;
    --highlight-color-valid: none !important;
    --highlight-color-invalid: none !important;

    --background-hover: none !important;

    --ripple-color: transparent;
    --background-activated: transparent;

    ion-icon {
      color: var(--ion-neutral-grey-default-color);
    }

    &+.assative-text {
      color: var(--ion-neutral-grey-default-color);
    }

    &.isFocused {
      &:not(.bg-white) {
        --border-color: var(--ion-primary-color-step-30) !important;
        --highlight-color-invalid: var(--ion-primary-color-step-30, #ABE8F7) !important;
        --highlight-color-valid: var(--ion-primary-color-step-30, #ABE8F7) !important;

        &+.assative-text {
          color: var(--ion-neutral-grey-default-color) !important;
        }
      }
    }

    &.item-interactive {
      &.item-has-value {
        &:not(.ion-invalid.ng-invalid) {
          --border-color: var(--ion-primary-color-step-30);
        }
      }
    }


    &.item-interactive-disabled {
      &:not(.item-has-focus) {
        .input-label {
          --color: var(--ion-neutral-grey-default-color) !important;
          color: var(--color) !important;
          opacity: 1 !important;
          --ion-border-color: var(--ion-primary-color-step-30);
        }

        // for disble
        ion-row {
          background: var(--ion-neutral-grey-light-b-color);
        }

        &:not(.ion-invalid) {
          --ion-border-color: var(--ion-neutral-white-color) !important;
          --border-color: var(--ion-neutral-white-color) !important;
        }

        ion-icon {
          color: var(--ion-neutral-grey-default-color);
        }

        .pull-tab {
          display: none;
        }

        &+.assative-text {
          color: var(--ion-neutral-grey-default-color) !important;
        }
      }
    }

    &.item-has-focus {
      &:not(.bg-white) {
        --border-color: var(--ion-primary-color-step-30) !important;

        // --highlight-color-focused
        ion-label {
          color: var(--color) !important;
          color: var(--color);
        }

        ion-icon {
          color: var(--ion-primary-color-step-30) !important;
        }

        // for focused
        ion-row {
          background: var(--ion-primary-color-step-5) !important;

          ion-col {
            .pull-tab {
              border-top: 10px solid #1EA5C7 !important;
            }
          }
        }

        &+.assative-text {
          color: var(--ion-neutral-grey-default-color) !important;
        }
      }
    }

    &.item-has-value,
    &.item-has-value.ng-touched.ng-dirty.ng-valid.ion-touched.ion-dirty.ion-valid {
      --border-color: var(--ion-primary-color-step-30);

      &+.assative-text {
        color: #000000;
      }
    }

    &.ng-touched.item-has-value.ng-dirty.ion-invalid.ion-touched.ion-dirty.ng-invalid,
    &.hasError,
    &.error {
      --border-color: var(--ion-primary-error-color) !important;

      ion-icon {
        color: var(--ion-primary-error-color) !important;
      }

      .pull-tab {
        border-top: 10px solid #D5DBDE !important;
      }

      &+.assative-text {
        color: var(--ion-primary-error-color) !important;
      }
    }

    // checkbox
    &.item-checkbox,
    &.item-radio {
      --min-height: 24px;/* 40px; */
      --max-height: 24px;
      padding-top: 16px !important;
      padding-bottom: 16px !important;

      ion-label {
        margin: 0px 8px 0px 0px !important;
      }
    }
    &.item-radio-modal {
      --min-height: auto !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      margin-bottom: 0 !important;

      ion-label {
        margin: 0px 8px 0px 0px !important;
      }
    }
  }
}

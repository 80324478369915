@import '../variables.scss';
@import '../mixins/mixins';

.biz {
  ion-toast {
    &.error {
      --background: var(--ion-primary-error-color) !important;
      --color: #000 !important;
      color: #000 !important;
    }

    &.success {
      --background: var(--ion-primary-color-step-100) !important;
      --color: #ffffff !important;
      color: #fff !important;
    }
    &.warning {
      --background: var(--ion-status-orange-color-step-15) !important;
      --color: #000000 !important;
      color: rgb(0, 0, 0) !important;
    }

    --border-radius: 0;

    &::part(message) {
      text-align: center;
    }
  }
}

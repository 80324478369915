@import "../variables.scss";
@import "../mixins/mixins";

.bottom-1px-solid-grey-default-color {
  border-bottom: 1px solid var(--ion-neutral-grey-default-color) !important;
}

.border-bottom-1px-solid-neutral-grey-light-b-color {
  border-bottom: 1px solid var(--ion-neutral-grey-light-b-color) !important;
}

.border-top-1px-solid-neutral-grey-light-b-color {
  border-top: 1px solid var(--ion-neutral-grey-light-b-color) !important;
}

.border-bottom-1px-solid-neutral-grey-medium-color {
  border-bottom: 1px solid var(--ion-neutral-grey-medium-color) !important;
}

.border-left-1px-solid-primary-color-step-30 {
  border-left: 1px solid var(--ion-primary-color-step-30) !important;
}
.border-bottom-1px-solid-primary-error {
  border-bottom: 1px solid var(--ion-primary-error-color) !important;
}

.border-bottom-blue {
  border-bottom: 1px solid var(--ion-primary-color-step-30) !important;
}

@import '../variables.scss';
@import '../mixins/mixins';

.biz {

  ion-button {
    text-transform: none;
    padding-inline-start: 0;
    padding-inline-end: 0;

    font-family: var(--ion-font-roboto-family) !important;

    &.button-default {
      border-radius: rem(12);
      min-height: rem(40);
      height: rem(40);
      font-size: rem(14);
      line-height: rem(16);
      white-space: normal;
      border: 0;
      box-shadow: none;
      --box-shadow: none;
      --border-radius: 12px;

      .button {
        --padding-top: rem(8);
        --padding-bottom: rem(8);
        --padding-start: rem(16);
        --padding-end: rem(12);
        padding-top: rem(16);
        min-height: rem(40);
        padding-bottom: rem(16);
        border-width: 0;
        border: none;
      }

      &.button-has-icon-only {
        min-width: rem(40) !important;
        width: rem(40) !important;
        --padding-start: 8px !important;
        --padding-end: 8px !important;
        --padding-start: 8px !important;
        --padding-end: 8px !important;
      }
    }

    &.button-large {
      border-radius: rem(16);
      min-height: rem(56);
      height: rem(56);
      font-size: rem(18);
      line-height: rem(24);
      white-space: normal;
      border: 0;
      box-shadow: none;
      --box-shadow: none;
      --border-radius: 16px;

      .button {
        --padding-top: rem(16) !important;
        --padding-bottom: rem(16) !important;
        --padding-start: rem(24) !important;
        --padding-end: rem(24) !important;
        padding-top: rem(16);
        max-height: rem(56);
        padding-bottom: rem(16);
        border-width: 0;
        border: none;

        &:after {
          display: none;
        }
      }

      &.button-has-icon-only {
        min-width: rem(56) !important;
        width: rem(56) !important;
        --padding-start: 16px !important;
        --padding-end: 16px !important;
        --padding-start: 16px !important;
        --padding-end: 16px !important;
      }
    }

    &.button-space {
      --padding-start: 32px;
      --padding-end: 32px;
    }

    &.button-small {
      font-size: rem(14);
      min-height: rem(32);
      height: rem(32);
      line-height: rem(16);
      font-weight: 500;
      letter-spacing: rem(0);
      --padding-top: 8px;
      --padding-bottom: 8px;
      --padding-start: 8px;
      --padding-end: 8px;
      --border-radius: 8px;

      .button {
        min-height: rem(32);
        --max-height: 32px;
        height: auto;
        padding-top: rem(8);
        padding-bottom: rem(8);
        border-width: 0;
        line-height: rem(16);
      }

      &.button-has-icon-only {
        min-width: rem(32) !important;
        width: rem(32) !important;
        --padding-top: 4px !important;
        --padding-bottom: 4px !important;
        --padding-start: 4px !important;
        --padding-end: 4px !important;
      }
    }

    &.button-default-flat {
      border-radius: rem(12);
      min-height: 24px;
      height: 24px;
      font-size: rem(14);
      line-height: rem(16);
      white-space: normal;
      border: 0;
      box-shadow: none;
      --box-shadow: none;
      --border-radius: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
      margin-inline: 0 !important;

      .button {
        min-height: 24px;
        --max-height: 24px;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        border-width: 0;
        line-height: rem(16);
        margin: 0 !important;
      }

      &.biz-tertiary {
        --background: none !important;
        --color: var(--ion-primary-color-step-100) !important;
      }
    }
  }
}

@import "../variables.scss";
@import "../mixins/mixins";

.biz {
  ion-loading {
    --spinner-color: var(--ion-primary-color-step-100);
  }
  .biz-custom-line-loader {
    overflow: hidden;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    .loader__element {
      height: 3px;
      width: 100%;
      background: var(--ion-primary-color-step-30);
      &:before {
        content: "";
        display: block;
        background-color: var(--ion-primary-color-step-100);
        height: 3px;
        width: 0;
        animation: getWidth 3s ease-in infinite;
      }
    }

    @keyframes getWidth {
      100% {
        width: 100%;
      }
    }
  }
}

@import "../variables";
@import "../mixins/mixins";

.biz {
  .biz-modal-class {
    --backdrop-opacity: 0;
    background: var(--ion-neutral-grey-light-b-color) !important;
    --box-shadow: var(--ion-diffuse-box-shadow) !important;

    .modal-wrapper {
      height: 100vh !important;
    }

    &.neutral-grey-light-backdrop {
      --backdrop-opacity: 0;
      background: var(--ion-neutral-grey-light-b-color) !important;
      --box-shadow: var(--ion-diffuse-box-shadow) !important;
    }

    ion-header {
      padding: 22px 16px 22px 32px;
      background: white !important;
      border-bottom: 1px solid var(--ion-neutral-grey-default-color);

      ion-toolbar {
        --min-height: 0 !important;
      }

      ion-buttons {
        margin-inline-start: 0 !important;

        ion-icon {
          margin-inline-start: 0 !important;
          margin-right: 24px !important;
        }
      }

      ion-button {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      ion-item {
        --min-height: auto !important;
      }

      ion-input {
        font-size: rem(20);
        line-height: rem(28);

        input {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        &:focus-within,
        &.isInputFocused {
          --padding-start: 0 !important;
        }

        input[disabled],
        .disabled {
          --padding-start: 0 !important;
        }
      }
    }

    ion-content {
      /* hide scroll bar */
      --offset-bottom: auto !important;
      --overflow: hidden;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .modal-view {
        padding: 22px 16px 22px 32px;
        overflow: auto !important;

        ion-item {
          align-items: flex-start;
          margin-bottom: 16px;

          ion-checkbox {
            margin-top: 2px !important;

            margin-inline: 0 24px !important;
          }
        }
      }

      .modal-view-radio {
        padding: 6px 16px 22px 32px;
      }
    }
  }

  @media (min-width: $desktop-width) {
    .biz-modal-class {
      &.width-md-100 {
        .modal-wrapper {
          width: 100% !important;
        }
      }

      .modal-wrapper {
        height: 100vh !important;
      }
    }
  }

  .biz-modal-class-type-a {
    --backdrop-opacity: 0.8 !important;
    &.make-scrollable {
      .modal-wrapper {
        ::part(scroll) {
          overflow-y: scroll !important;
          overflow-x: hidden;
        }
      }
    }
    &.mh-480px {
      .modal-wrapper {
        --min-height: 480px !important;
        min-height: var(--min-height) !important;
      }
    }

@media (max-width: $mobile-width) {
  &.mh-sm-480px {
    .modal-wrapper {
      --min-height: 480px !important;
      min-height: var(--min-height) !important;
    }
  }
}

    .modal-wrapper {
      box-shadow: var(--ion-diffuse-box-shadow);
      margin: 80px auto 64px;
      border-radius: rem(8);
      --height: auto;
      --max-height: 100%;
      --min-height: 448px;
      height: var(--height);
      min-height: var(--min-height);
      max-height: var(--max-height);
      width: 311px;

      ::part(scroll) {
        overflow: hidden;
      }

      .modal-header {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 64px;
        background: white;
      }
      .modal-dialog {
        overflow-y: initial !important;
      }

      .modal-body {
        width: 100%;
        position: relative;
        margin-top: 64px;
        margin-bottom: 54px;
        padding: 0 24px;
        height: 330px;
        overflow-y: scroll !important;
      }

      .modal-footer {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 54px;
        background: white;
      }
    }

    &.modal-wrapper-with-232px-height {
      .modal-wrapper {
        --min-height: 232px !important;
        min-height: var(--min-height) !important;
      }
    }

    &.modal-wrapper-with-236px-height {
      .modal-wrapper {
        --min-height: 236px !important;
        min-height: var(--min-height) !important;
      }
    }
    &.modal-wrapper-with-690px-height {
      .modal-wrapper {
        --min-height: 690px !important;
        min-height: var(--min-height) !important;
      }
    }
    &.modal-wrapper-with-118px-height {
      .modal-wrapper {
        --min-height: 118px !important;
        min-height: var(--min-height) !important;
      }
    }
  }

  @media (min-width: $desktop-width) {
    .biz-modal-class-type-a {
      &.mh-md-450px {
        .modal-wrapper {
          --min-height: 450px !important;
          min-height: var(--min-height) !important;
        }
      }
      .modal-wrapper {
        width: 413px !important;
      }
    }
  }

  .modal-div-body {
    position: relative;

    .modal-div-header {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99999999;

      &.white-bg {
        background: white;
      }

      @media (min-width: $desktop-width) {
        width: 100%;
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
      }
    }

    .modal-div-body {
      overflow: auto !important;
      padding-right: 16px;
      padding-left: 32px;
    }

    .cal-time-events {
      margin-top: 180px !important;
    }

    .cal-day-column {
      border: none !important;
    }

    .cal-time-label-column {
      width: 48px !important;
    }

    .cal-week-view .cal-day-headers {
      padding-left: 48px;
    }

    .cal-week-view .cal-time {
      width: 48px;
    }

    .cal-week-view .cal-day-headers .cal-header {
      text-align: left;
    }

    .cal-hour-start {
      border: none !important;
    }

    .cal-after-hour-start {
      border-bottom: 1px solid #d5dbde !important;
    }

    .cal-week-view .cal-ends-within-day .cal-event {
      align-items: flex-start !important;
    }
  }

  .biz-modal-class-type-no-background {
    --background: none !important;
    --backdrop-opacity: 0.3 !important;
    --box-shadow: none !important;
    box-shadow: var(--box-shadow) !important;

    ion-backdrop {
      background-color: none !important;
    }

    .modal-wrapper {
      background: none !important;

      ion-content {
        --background: none !important;

        ion-item {
          --background: none !important;
        }
      }
    }
  }

  .biz-modal-overlay {
    position: fixed;
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    width: 100% !important;
    height: 100%;
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    overflow: hidden;

    .biz-modal-content-header {
      width: 100% !important;
      top: 0;
      position: fixed !important;
      background: white !important;
      margin-top: constant(safe-area-inset-top);
      margin-top: env(safe-area-inset-top);
      @media (min-width: $desktop-width) {
        width: 634px !important;
        left: 50%;
        right: 50%;
        @include transform(translateX(-50%));
      }
    }
  }
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/theme";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./theme/calendarStyle";
@import "../node_modules/swiper/swiper";

.bold {
  font-weight: 500 !important;
  display: block;
  padding-top: 7px;
}
.ion-page {
  display: block;
}
.table-bordered > * {
  border: none !important;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: none !important;
  text-transform: capitalize;
  color: #000000;
  font-family: Roboto;
  font-size: 12.8px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.table-bordered > thead > tr > th {
  text-transform: capitalize;
  color: #97a1a6;
  font-family: Roboto;
  font-size: 12.8px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
.toast-bg {
  --background: red !important;
  --border-radius: none;
}

.no-border {
  border-left: none !important;
}
.bb-none {
  background: none !important;
  border: none !important;
}

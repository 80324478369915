.setari-card {
  height: rem(116);
  border-radius: rem(4) 0 0 rem(4);
}

.radius-10 {
  border-radius: rem(10);
}

@media screen and (max-width: $mobile-width) and (min-width: $xs-width) {
  .setari-card {
    height: rem(170);
  }
}

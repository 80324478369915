@import "./variables.scss";
@import "./mixins/mixins";
@import "pattern";
@import "./fonts.scss";
@import "./components/index";

.flex-1 {
  flex: 1;
}

.flex-45-perc {
  flex: 45%;
}

.flex {
  @include flexbox();
}

.flex-v-center {
  align-items: center;
}

.flex-align-content-center {
  align-content: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-0 {
  flex: 0;
}

.inline-flex {
  display: inline-flex;
}

.mt-0-reset {
  margin-top: 0 !important;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-row {
  @include flex-direction(row);
}

.flex-column {
  @include flex-direction(column);
}

.flex-row-reverse {
  @include flex-direction(row-reverse);
}

.flex-column-reverse {
  @include flex-direction(column-reverse);
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.overflow-y-visible {
  overflow-y: visible;
}
.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll !important
  ;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-visible {
  overflow: visible;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-hide {
  opacity: 0;
}

.opacity-0 {
  opacity: 0;
}

.visibility-hidden {
  visibility: hidden !important;
}

.pos-absolute {
  position: absolute;
}

:root {
  // Global enforce.
  :focus {
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}
.opacity-05 {
  opacity: 0.5 !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.contain-size-style {
  contain: size style;
}
.transform-scale-2 {
  @include transform(scale(2));
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.safe-zone{
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important;
}

@import '../variables.scss';
@import '../mixins/mixins';

.biz {
  ion-toggle {
    height: 24px !important;
    --background: white;
    --handle-background: #97A1A6;
    --handle-spacing: 4px !important;
    --handle-width: 16px !important;
    --handle-height: 16px !important;
    padding: 16px 12px 16px 0 !important;
    width: 36px !important;

    &::part(track) {
      border: 2px solid #97A1A6 !important;
    }


    &.toggle-checked {
      &::part(track) {
        border: 2px solid #1EA5C7 !important;
      }
    }
  }
}

@import "../variables.scss";
@import "../mixins/mixins";

.biz-custom-select-div {
  width: 100%;
  cursor: pointer;
  &.biz-custom-select-div-disabled {
    & > ion-label,
    & > label {
      color: var(--ion-neutral-grey-default-color) !important;
    }
    .biz-custom-select {
      background: var(--ion-neutral-grey-light-b-color) !important;
      border-bottom: 1px solid var(--ion-neutral-white-color) !important;
      input {
        color: var(--ion-neutral-grey-medium-color) !important;
      }
    }
  }

  & > ion-label,
  & > label {
    font-family: var(--ion-font-roboto-family);
    font-weight: 500;
    letter-spacing: 0;

    font-size: 12.8px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    display: inline-block !important;
    margin-bottom: 6px !important;
  }

  .biz-custom-select {
    @include flexbox;
    @include flex-direction(row);
    width: 100%;
    @include justify-content(flex-start);
    @include align-items(center);

    &.border-light-blue {
      border-bottom: 1px solid #abe8f7;
    }

    &.border-light-grey {
      border-bottom: 1px solid var(--ion-neutral-grey-medium-color);
    }

    .select-display {
      padding: 8px 8px 8px 0;
      display: flex;
      flex-wrap: wrap;
    }

    .input-select-display {
      flex: 1;

      input {
        width: 100%;
        font-family: var(--ion-font-roboto-family);
        font-size: rem(16) !important;
        line-height: rem(24) !important;
        letter-spacing: 0;
        border: none;
        outline: none;
        background: none;
        padding: 0;
        color: var(--ion-neutral-black-color);
        cursor: pointer;

        &::placeholder {
          color: var(--ion-neutral-grey-medium-color);
        }
      }
    }

    .icon {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      padding: 8px 8px 8px 0px;

      ion-icon {
        height: rem(24);
        width: rem(24);
      }
    }
  }
}

.custom-selection-drop-down {
  @include flexbox;
  @include flex-direction(column);
  font-family: var(--ion-font-roboto-family);
  font-size: rem(16) !important;
  line-height: rem(24) !important;
  letter-spacing: 0;
  position: relative;
  display: inline-block;

  .selection-display {
    @include flexbox;
    @include flex-direction(row);
    @include align-items(center);
    padding: 8px 0;
    min-width: 70px;
    text-align: center;
    border-bottom: 1px solid var(--ion-primary-color-step-30);
    height: 40px;
    padding: 8px 0;

    span {
      margin-right: 20px;
    }
  }

  .selection-dropdown {
    display: none;
    width: 153px;
    background: white;
    position: absolute;
    z-index: 100000;
    box-shadow: var(--ion-light-box-shadow);

    &.show {
      @include flexbox;
      @include flex-direction(column);
    }

    .options {
      width: 153px;
      padding: 16px;
    }
  }
}

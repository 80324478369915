@import '../variables';
@import '../mixins/mixins';

.biz {
  ion-header {
    background-color: white !important;
    padding: 16px 16px 25px 32px !important;
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 3000000;

    &.header-type-a,
    &.header-type-b {
      border-bottom: 1px solid var(--ion-neutral-grey-medium-color);
      box-shadow: var(--ion-light-box-shadow);

      ion-toolbar {
        --min-height: auto !important;

        ion-title {
          padding-inline: 0 !important;
        }

        ion-buttons {
          margin-inline-start: 0 !important;

          ion-icon {
            margin-right: 0 !important;
          }

          &.slot-start {
            margin-right: 8px !important;
          }
        }

        ion-button {
          margin: 0 !important;
        }
      }

      @media (min-width: $desktop-width) {
        &.no-border-lg {
          border: none !important;
        }

      }

      &.header-With-8px-Bottom-Radius {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        overflow: hidden !important;
      }

    }

    &.header-type-b {
      padding: 0 !important;
      box-shadow: 0 23px 24px -15px rgba(0, 0, 0, 0.1), 0 30px 64px -15px rgba(0, 0, 0, 0.27) !important;

      ion-toolbar {
        padding: 16px 16px 25px 32px !important;
      }
    }

    &.no-box-shadow {
      box-shadow: none !important;
    }
  }
}

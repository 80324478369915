@import "../variables.scss";
@import "../mixins/mixins";

.biz {
  .biz-form-group {
    ion-row {
      width: 100% !important;
      height: 40px !important;
    }

    ion-col {
      min-height: auto !important;
      height: 40px !important;
      padding: 8px 0 !important;
    }
  }

  ion-input {
    --padding-start: 0;
    font-family: var(--ion-font-roboto-family);
    font-size: rem(16) !important;
    line-height: rem(24) !important;
    letter-spacing: 0;

    input {
      height: 24px;
      width: 100%;
      &[type="date"],
      &[type="time"] {
        padding: 0 !important;
      }
    }

    --placeholder-color: var(--ion-neutral-grey-default-color);
    --placeholder-opacity: 1;

    &:focus-within,
    &.isInputFocused {
      --padding-start: 8px !important;
      --placeholder-opacity: 0;
    }

    input[disabled],
    .disabled {
      background: var(--ion-neutral-grey-light-b-color);
      color: var(--ion-neutral-grey-medium-color);
      --padding-start: 8px !important;
    }
  }

  .biz-custom-form-group {
    @include flexbox;
    width: 100%;
    @include justify-content(flex-start);
    @include align-items(center);
    @include flex-direction(row);

    ion-input {
      width: 1%;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;

      input {
        width: 100%;
      }
    }

    ion-icon {
      height: 24px !important;
      width: 24px !important;
    }

    &.useIcon {
      padding-right: 8px !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.item-native {
  min-height: 40px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none !important;
}

.input-spinner {
  z-index: 2;
}
.iti {
  .iti__flag-container {
    .dropdown-menu {
      .search-container {
        #country-search-box {
          padding: 5px !important;
        }
      }
    }
  }
  > input {
    border: none !important;
  }
}
.biz-custom-select .iti .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: none !important;
}

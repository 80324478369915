// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-roboto-family: "Roboto", sans-serif;
  --ion-font-poppins-font: "Poppins", Arial, sans-serif;

  /** app color **/

  // primary app colors
  --ion-primary-color-step-100: #1ea5c7;
  --ion-primary-color-step-100-rgb: rgb(30, 165, 199);
  --ion-primary-color-step-110: #198ca9;
  --ion-primary-color-step-110-rgb: rgb(25, 140, 169);
  --ion-primary-color-step-70: #5ccae6;
  --ion-primary-color-step-30: #abe8f7;
  --ion-primary-color-step-10: #e3f9ff;
  --ion-primary-color-step-5: #f5fcff;
  --ion-primary-error-color: #f23d3d;

  --ion-primary-color-text: #002f49;

  // status colors
  --ion-status-green-color-step-100: #2ea81b;
  --ion-status-green-color-step-20: #d5eed1;
  --ion-status-green-color-step-10: #eaf6e8;

  --ion-status-yellow-color-step-100: #ffc715;
  --ion-status-yellow-color-step-40: #ffe9a1;

  --ion-status-blue-color-step-100: #3093f8;
  --ion-status-blue-color-step-20: #d6e9fe;
  --ion-status-blue-color-step-10: #eaf4fe;

  --ion-status-orange-color-step-100: #f77c00;
  --ion-status-orange-color-step-20: #fde5cc;
  --ion-status-orange-color-step-15: #feecd9;
  --ion-status-orange-color-step-10: #fef1e5;
  // neutral colors
  --ion-neutral-black-color: #000000;
  --ion-neutral-grey-default-color: #97a1a6;
  --ion-neutral-grey-default-color-rgb: rgb(151, 161, 166);
  --ion-neutral-grey-medium-color: #d5dbde;

  --ion-neutral-grey-light-color: #e3e9ec;
  --ion-neutral-grey-light-b-color: #f2f8fa;
  --ion-neutral-grey-light-b-color-rgb: rgb(242, 248, 250);

  --ion-neutral-grey-ultralight-color: #f8fbfd;
  --ion-neutral-white-color: #ffffff;

  // shadow
  --ion-light-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1),
    0 6px 14px -8px rgba(20, 24, 26, 0.3);
  --ion-tiny-box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.1),
    0 5px 14px -9px rgba(20, 24, 26, 0.3), 0 0.5px 0.5px 0 rgba(0, 0, 0, 0.3);
  --ion-diffuse-box-shadow: 0 23px 24px -15px rgba(0, 0, 0, 0.1),
    0 30px 64px -15px rgba(0, 0, 0, 0.27);
  /* ---------- */
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  --ion-color-success: #008641;
  --ion-color-success-rgb: 0, 134, 65;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #007639;
  --ion-color-success-tint: #1a9254;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #ef6d53;
  --ion-color-danger-rgb: 239, 109, 83;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255 255 255;
  --ion-color-danger-shade: #d26049;
  --ion-color-danger-tint: #f17c64;
  --ion-color-dark: #191a19;
  --ion-color-dark-rgb: 25, 26, 25;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #161716;
  --ion-color-dark-tint: #303130;
  --ion-color-medium: #747c78;
  --ion-color-medium-rgb: 116, 124, 120;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #666d6a;
  --ion-color-medium-tint: #828986;
  --ion-color-light: #d1d4d2;
  --ion-color-light-rgb: 209, 212, 210;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// Custom variables.
$baseFontSizePx: 16px;
$grid-breakpoints: ();
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
  ),
  $grid-breakpoints
);
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
    6: 6px,
    8: 8px,
    11: 11px,
    10: 10px,
    12: 12px,
    13: 13px,
    14: 14px,
    15: 15px,
    16: 16px,
    17: 17px,
    18: 18px,
    19: 19px,
    20: 20px,
    21: 21px,
    22: 22px,
    23: 23px,
    24: 24px,
    26: 26px,
    27: 27px,
    28: 28px,
    30: 30px,
    32: 32px,
    36: 36px,
    37: 37px,
    38: 38px,
    40: 40px,
    42: 42px,
    44: 44px,
    46: 46px,
    48: 48px,
    52: 52px,
    56: 56px,
    60: 60px,
    64: 64px,
    72: 72px,
    73: 73px,
    74: 74px,
    80: 80px,
    90: 90px,
    93: 93px,
    96: 96px,
    100: 100px,
    120: 120px,
    149: 149px,
    128: 128px,
    138: 138px,
    144: 144px,
    150: 150px,
    168: 168px,
    200: 200px,
    260: 260px,
    112: rem(112),
    116: rem(116),
  ),
  $spacers
);

/** Custom colors **/

// Colors
$white: #ffffff;
$black: var(--ion-neutral-black-color);
$blue: #0087ea;
$danger: #ef6d53;
$warning: #ef6d53;
$warning-2: #f77c00;
$warning-3: #feecd9;
$warning-4: #ffe9a1;
//Primary
$green: #008641;
$green-2: #2ea81b;
$green-3: #d5eed1;
$green-4: #eaf6e8;
$red: #e1261c;
$red-2: #f23d3d;
$red-3: #fef1e5;
$black: #191a1a;
$blue: #0087ea;
$blue-2: #3093f8;
$blue-3: #d6e9fe;
$blue-4: #eaf4fe;
$blue-5: #1ea5c7;
$blue-6: #e3f9ff;
$blue-7: #c7f3ff;
//Secondary
$secondary-grey: #747c78;
$secondary-blue: #0077cc;
$grey: #636463;
$grey-2: #e3e9ec;
$grey3: #8b8b8b;
$grey-4: #4c4f51;
$grey-5: #4a4f4c;
$grey-6: #343439;
$medium-grey: #a7aaac;
$medium-grey-2: #d1d4d2;
$medium-grey-3: #d1d4d2;
$medium-grey-4: #6e6e6e;
$light-grey: #d1d3d4;
$light-grey-2: #f7f8f7;
$light-grey-3: #97a1a6;
$light-green-5: #bfe2d0;
$ultra-light-grey: #f2f9f5;
$ultra-light-grey-2: #f2f2f3;
$dark-grey: #454545;
//Tertiary
$dark-green: #006330;
$dark-green-b: #004220;
$medium-green: #bfe2ce;
$medium-green-2: #a7aca9;
$medium-green-3: #bfe2d0;
$light-green: #e5f2ec;
$light-green-3: #2d7d43;
$ultra-light-green: #f2f9f5;
$light-red: #c32328;
$alt-bg: #f5f9fc;
$deep-blue: #1c5393;
$light-blue: #236faa;
$yellow: #f7ce23;
$yellow-2: #f5d800;
$yellow-3: #ffe9a1;
$bg-dark: #1a1919;
$light-green-2: #5fb63d;
$light-green-5: #bfe2d0;
$purple: #9b57e0;
$purple-2: #f7edff;
$purple-3: #eddbff;
$light-grey-4: #F8FBFD;

$box-shadow-1: var(--ion-light-box-shadow);
$box-shadow-2: var(--ion-tiny-box-shadow);
$box-shadow-3: var(--ion-diffuse-box-shadow);

$desktop-width: 820px;
$tablet-width: 768px;
$mobile-width: 480px;
$xs-width: 290px;

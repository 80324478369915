@import "../variables";
@import "../mixins/mixins";

$font-size-base: 1rem;
$font-family-base: var(--ion-font-roboto-family);
$poppins-family-font: var(--ion-font-poppins-font);

.poppings-family-font {
  font-family: $poppins-family-font;
}

.roboto-family-font {
  font-family: $font-family-base;
}

.text-weight-400 {
  font-weight: 400 !important;
}

.text-weight-regular {
  font-weight: 400 !important;
}

.text-weight-medium {
  font-weight: 500 !important;
}

.text-weight-normal {
  font-weight: normal !important;
}

.text-weight-light {
  font-weight: lighter !important;
}

.text-weight-bold {
  font-weight: 600;
}

.text-small {
  font-size: rem(13) !important;
  line-height: rem(16) !important;
}

.text-x-small {
  font-size: rem(11);
  line-height: rem(13);
}

h1,
h2,
h3,
h4,
h6 {
  margin: 0;
  letter-spacing: 0;
  font-weight: 500;
}

h5 {
  line-height: rem(24);
}

h2,
.h2 {
  line-height: rem(32) !important;
  font-size: rem(24) !important;
}

h3,
.h3 {
  line-height: rem(24) !important;
  font-size: rem(20) !important;
}

h4,
.h4 {
  font-family: var(--ion-font-poppins-font);
  font-size: rem(25) !important;
  line-height: rem(32) !important;
}

h5,
.h5 {
  font-family: var(--ion-font-roboto-family);
  font-size: rem(20) !important;
  line-height: rem(28) !important;
  font-weight: 400 !important;
}

.link-small {
  font-size: rem(14);
  line-height: rem(16);
}

.h2-title {
  font-size: rem(24);
  line-height: rem(24);
}

.s11-h12 {
  font-size: rem(11) !important;
  line-height: rem(12) !important;
}

.s11-h13 {
  font-size: rem(11) !important;
  line-height: rem(13) !important;
}

.s12pt8-h16 {
  font-size: rem(12.8) !important;
  line-height: rem(16) !important;
}

.s13-h16 {
  font-size: rem(13) !important;
  line-height: rem(16) !important;
}

.s14-h16 {
  font-size: rem(14) !important;
  line-height: rem(16) !important;
}

.s14pt8-h16 {
  font-size: rem(14.8) !important;
  line-height: rem(16) !important;
}

.s16-h20 {
  font-size: rem(16) !important;
  line-height: rem(20) !important;
}

.s16-h19 {
  font-size: rem(16) !important;
  line-height: rem(19) !important;
}

.s16-h22 {
  font-size: rem(16) !important;
  line-height: rem(22) !important;
}

.s16-h24 {
  font-size: rem(16) !important;
  line-height: rem(24) !important;
}

.s18-h20 {
  font-size: rem(18) !important;
  line-height: rem(20) !important;
}

.s18-h24 {
  font-size: rem(18) !important;
  line-height: rem(24) !important;
}

.s20-h24 {
  font-size: rem(20) !important;
  line-height: rem(24) !important;
}

.s20-h28 {
  font-size: rem(20) !important;
  line-height: rem(28) !important;
}

.s24-h32 {
  font-size: rem(24) !important;
  line-height: rem(32) !important;
}

.s25-h32 {
  font-size: rem(25) !important;
  line-height: rem(32) !important;
}

.s32-h38 {
  font-size: rem(32) !important;
  line-height: rem(38) !important;
}

.s42-h48 {
  font-size: rem(42) !important;
  line-height: rem(48) !important;
}

.s32-h38 {
  font-size: rem(32) !important;
  line-height: rem(38) !important;
}

.s13-h15 {
  font-size: rem(13) !important;
  line-height: rem(15) !important;
}

.s32-h38 {
  font-size: rem(32) !important;
  line-height: rem(38) !important;
}

.s40-h40 {
  font-size: rem(40) !important;
  line-height: rem(40) !important;
}

.card-content-14pt8 {
  font-family: Roboto;
  font-size: rem(14.8);
  letter-spacing: rem(-0.3);
  line-height: rem(16);
}
.card-content-12pt8 {
  font-family: Roboto;
  font-size: 12.8px;
  letter-spacing: 0;
  line-height: 16px;
}
.white-space-normal {
  white-space: normal;
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-normal {
  font-size: rem(16);
  line-height: rem(22);
}

.text-normal-important {
  font-size: rem(16) !important;
  line-height: rem(22) !important;
  font-family: $font-family-base !important;
}

.label-normal {
  font-size: rem(14);
  line-height: rem(16);
  font-weight: normal;
}

.label-large {
  font-size: rem(20);
  line-height: rem(24);
  font-weight: 600;
}

.label-large-light {
  font-size: rem(20);
  line-height: rem(24);
  font-weight: 500;
}

.x-large {
  font-size: rem(32);
  line-height: rem(38);
}

.font-x-large {
  font-size: rem(26);
  line-height: rem(38);
}

.label-x-large {
  font-size: rem(24);
  line-height: rem(32);
  font-weight: 600;
}

.input-label,
ion-label.input-label,
.item .ion-label {
  font-size: rem(12.8) !important;
  line-height: rem(16) !important;
  transform: none !important;
  font-weight: normal;
}

.input-label-2,
ion-label.input-label-2,
.item .ion-label {
  font-size: rem(12.8) !important;
  line-height: rem(16) !important;
  transform: none !important;
  font-weight: normal;
}

.lh-16 {
  line-height: rem(16);
}

.lh-22 {
  line-height: rem(22);
}

.ls-min-02 {
  letter-spacing: -0.2px;
}

.ls-min-01 {
  letter-spacing: -0.1px;
}

.ls-02 {
  letter-spacing: -0.2px;
}

.ls-03 {
  letter-spacing: -0.3px;
}

.ls-05 {
  letter-spacing: -0.5px;
}

.ls-01 {
  letter-spacing: -0.1px;
}

.ls-0 {
  letter-spacing: 0;
}

.border-danger {
  padding-bottom: rem(7);
  border-bottom: rem(1) solid $red;
}

.border-green {
  padding-bottom: rem(7);
  border-bottom: rem(1) solid $green;
}

.bottom-border {
  border-bottom: rem(1) solid $red;
}

.text-nowrap {
  word-wrap: nowrap;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-event {
  pointer-events: none;
}

.font-reset {
  font: inherit !important;
}

.shadow-1 {
  box-shadow: $box-shadow-1;
}

.shadow-3 {
  box-shadow: $box-shadow-3;
}

.font-128 {
  font-size: rem(128);
}

.z-index-999 {
  z-index: 999;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right !important;
}

a.no-decoration {
  text-decoration: none !important;
}

.is-not-visible {
  visibility: hidden !important;
}

.upper {
  text-transform: uppercase !important;
}

.s32-h32 {
  font-size: rem(32) !important;
  line-height: rem(32) !important;
}

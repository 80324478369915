@import "../variables.scss";
@import "../mixins/mixins";

.biz {
  ion-radio-group {
    ion-list-header {
      padding-inline-start: 0 !important;
    }

    ion-radio {
      margin-inline-end: 12px !important;
      --size: 24px;
      --border-color: var(--ion-neutral-grey-default-color) !important;
      margin-inline: 0 12px !important; // 4px 36px

      --padding-start: 0;
      --inner-padding-end: 0;
      --inner-border-width: 0;
      --ion-safe-area-left: 0;
      --inner-padding-top: 0;
      --inner-padding-bottom: 0;
      --inner-padding-left: 0;
      --inner-padding-right: 0;
      --inner-padding-start: 0;
      --padding-right: 0;
      --padding-left: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      --min-height: 24px;
      width: 24px;
      height: 24px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      &.radio-disabled {
        &::part(container) {
          border-color: var(--ion-neutral-grey-default-color) !important;
        }

        &::part(mark) {
          background: var(--ion-neutral-grey-default-color) !important;
        }
      }
    }

    ion-item {
      &:not(.item-radio-checked) {
        .custom-radio-type-a {
          p,
          ion-label {
            color: var(--ion-neutral-grey-default-color);
          }

          /* input ion-item */
          ion-item {
            --ion-border-color: #f2f8fa !important;
            --border-color: #f2f8fa !important;

            // for disble
            ion-row {
              background: var(--ion-neutral-grey-light-b-color);
            }

            ion-icon {
              color: var(--ion-neutral-grey-light-color);
            }

            ion-input {
              --color: #626e73;
              --placeholder-color: #626e73;
              --padding-start: 8px !important;
            }
          }
        }
      }

      &.item-radio-checked {
        custom-radio-type-a {
          ion-label {
            color: #000;
          }
        }
      }

      &.item-interactive-disabled {
        ion-item {
          --ion-border-color: #f2f8fa !important;
          --border-color: #f2f8fa !important;
        }
      }
    }
  }
}

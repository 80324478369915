@import '../variables.scss';
@import '../mixins/mixins';

.biz {
  ion-chip {
    border-radius: 8px;
    -webkit-font-smoothing: antialiased;
    margin: 0;

    font-family: var(--ion-font-roboto-family);
    font-weight: 500;
    letter-spacing: 0;

    ion-icon {
      margin-inline: 8px 0px;
    }

    &.chip,
    &.chip-radio {
      height: 40px;

      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }

    @media (max-width: $tablet-width) {
      &.chip,
      &.chip-radio {
        height: auto !important;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &.chip-radio {
      padding: 9px 12px;
    }

    &.chip {
      padding: 12px 10px 12px 16px;
      border-radius: 12px !important;

      ion-icon {
        margin-inline: 12px 0 !important;
      }
    }

    &.input-chip {
      padding: 8px;
      height: 32px;

      font-size: 12.8px;
      line-height: 16px;
    }


    &.selected {
      border: 2px solid #5CCAE6;
      background: #E3F9FF;
      color: #198CA9;
    }

    &.default {
      border: 2px solid #97A1A6;
      background: #FFFFFF;
      color: #000000;
    }
  }
}

.ion-color-biz-radio-chip-active {
  --ion-color-base: #198CA9;
  background: #E3F9FF;
  border: 2px solid #5CCAE6;
  --background: #E3F9FF;
  --color: var(--ion-color-base);
  --ion-color-base-rgb: rgb(25, 140, 169);
  --ion-color-shade: var(--ion-color-base);
}

.ion-color-biz-radio-chip-default {
  --ion-color-base: #000;
  background: #FFFFFF;
  border: 2px solid #97A1A6;
  --background: #FFFFFF;
  --color: var(--ion-color-base);
  --ion-color-base-rgb: rgb(151, 161, 166);
  --ion-color-shade: var(--ion-color-base);
}

.ion-color-biz-chip-secondary {
  --ion-color-base: #198CA9;
  background: #FFFFFF;
  border: 2px solid #5CCAE6;
  --background: #FFFFFF;
  --color: var(--ion-color-base);
  --ion-color-base-rgb: rgb(25, 140, 169);
  --ion-color-shade: var(--ion-color-base);
}

.ion-color-biz-radio-chip-default-b-no-outline {
  --ion-color-base: #97A1A6;
  background: #FFFFFF;
  --background: #FFFFFF;
  --color: var(--ion-color-base);
  --ion-color-base-rgb: rgb(151, 161, 166);
  --ion-color-shade: var(--ion-color-base);
  padding: 0 !important;
}

.ion-color-biz-chip-secondary-b-no-outline {
  --ion-color-base: #198CA9 !important;
  background: #FFFFFF;
  --background: #FFFFFF;
  --color: var(--ion-color-base);
  --ion-color-base-rgb: rgb(25, 140, 169) !important;
  --ion-color-shade: var(--ion-color-base);
  padding: 0 !important;
}

.ion-color-biz-chip-secondary-c-no-outline {
  --ion-color-base: #1EA5C7;
  background: #FFFFFF;
  --background: #FFFFFF;
  --color: var(--ion-color-base);
  --ion-color-base-rgb: rgb(30, 165, 199);
  --ion-color-shade: var(--ion-color-base);
  padding: 0 !important;
}
